import React from "react";

const Communities = () => {
  const items = [
    {
      img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712475684/RealEstate/b21e7f_12cb1124092d47f1a1be4421a94f5b3a_mv2_llqif0.webp",
      title: "FAMOUS PLACES",
    },
    {
      img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715652302/RealEstate/01_wxcgsa.jpg",
      title: "Anand Bhawan",
    },
    {
      img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715652302/RealEstate/02_yk8o2l.jpg",
      title: "Sangam",
    },
    {
      img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715652302/RealEstate/03_f5nsy9.jpg",
      title: "Allahabad University",
    },
    {
      img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715652302/RealEstate/04_xbbp9e.jpg",
      title: "International Airport Prayagraj",
    },
    {
      img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715652303/RealEstate/05_ssyryh.jpg",
      title: "International Stadium Prayagraj",
    },
    {
      img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715652303/RealEstate/06_un33hd.jpg",
      title: "UP board office",
    },
    {
      img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715652303/RealEstate/07_hmke4t.jpg",
      title: "Allahabad High Court",
    },
    // {
    //   img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712476566/RealEstate/b21e7f_47e7037e2b7a439cb7509118dc3db9eb_mv2_jpxb2m.webp",
    //   title: "BY THE BEACH",
    // },
    // {
    //   img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712475669/RealEstate/b21e7f_58e27763949b48bc9840f7e441849cb6_mv2_qekuuy.webp",
    //   title: "HOUSE",
    // },
    // {
    //   img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712476682/RealEstate/b21e7f_8f752437e2fe4f59a0d7a29b9c06e0f9_mv2_srjfzo.webp",
    //   title: "CONDO",
    // },
    // {
    //   img: "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712475669/RealEstate/b21e7f_58e27763949b48bc9840f7e441849cb6_mv2_qekuuy.webp",
    //   title: "TOWNHOUSE",
    // },
  ];
  return (
    <div className="communities-section">
      <div className="common-container">
        <div className="communities-container">
          {items?.map((item, index) => (
            <div
              key={index}
              className="community__item"
              style={{ backgroundImage: `url(${item?.img})` }}
            >
              <h3 className="community__item--title">{item?.title}</h3>
              <div className="item-overlay"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Communities;
