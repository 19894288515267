import React from "react";
import { blogData } from "../../utils/blogData";
import SingleBlog from "./SingleBlog";

const Blogs = () => {
  return (
    <div className="blogs-section">
      {blogData?.map((blog, i) => (
        <SingleBlog key={i} blog={blog} />
      ))}
    </div>
  );
};

export default Blogs;
