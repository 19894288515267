import React from "react";
import Navbar from "../../containers/Shared/Navbar";
import Footer from "../../containers/Shared/Footer";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <div className="aboutUs-page">
        <div className="common-container">
          <h1 className="aboutUs__header">Message from the director!</h1>

          <div className="aboutUs__item">
            <h1 className="aboutUs__content--title">An Approach to Excel</h1>
            <div className="aboutUs__content__desc">
              <p>R.K. Sahu</p>
              <p>Founder & C.E.O. - R.S. Group</p>
            </div>
            <br />
            <div className="aboutUs__content__desc">
              <p>
                It is a dream of every human being to own a residence. I am
                grateful to God for giving me this beautiful chance to fulfill
                their longing dreams. Today, R.S. Group holds a prestigious
                position in the field of Real Estate.
              </p>
              <br />
              <p>
                Our team consists of a number of skilled professionals. Under
                their supervision, we have successfully completed several
                projects. R.S. Group is further creating various townships in
                the Holy City of Prayagraj and other cities. The sole reason why
                our dreams come true is our honesty and dedicated work.
              </p>
              <br />
              <p>
                We think of our customers as our God, not just in terms of word,
                but also the real meaning. That is because, I wouldn’t be where
                I am without their dedicated support and trust. Due to this, we
                prioritize their satisfaction above everything else.
              </p>
              <br />
              <p>
                In my opinion, earning a large sum of money is not rocket
                science as long as you are honest and do not deceive anyone.{" "}
              </p>
              <p>
                So, we invite you to join our mission and play a role in our
                society to create a history in your own life too!
              </p>
              <p>
                All glory is followed by a risk to begin something. We are
                lending you a hand, and you just need to put your step forward.
              </p>
            </div>

            <div className="aboutUs__regards">
              <div className="aboutUs__regards-wrapper">
                <h5 className="aboutUs__regards--title">Regards</h5>
                <h4 className="aboutUs__regards--text">
                  {/* Best Wishes and GROW WITH US... */}
                  Best Wishes and Grow with Us!
                </h4>
                {/* <h4 className="aboutUs__regards--text">Managing Director</h4> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
