import React from "react";
import Navbar from "../../containers/Shared/Navbar";
import Footer from "../../containers/Shared/Footer";
import PropertyManagement from "./PropertyManagement";
import WhatIsIncluded from "./WhatIsIncluded";
import Services from "./Services";
import WhoWeAre from "./WhoWeAre";
import WhyChooseUs from "./WhyChooseUs";

const PropertyManagementPage = () => {
  return (
    <div>
      <Navbar />
      <PropertyManagement />
      <WhoWeAre />
      <WhyChooseUs />
      <WhatIsIncluded />
      <Services />
      <Footer />
    </div>
  );
};

export default PropertyManagementPage;
