import React from "react";
import Navbar from "../../containers/Shared/Navbar";
import Footer from "../../containers/Shared/Footer";
import GettingToKnowUs from "./GettingToKnowUs";
import QuoteSection from "./QuoteSection";
import Communities from "./Communities";
import Hero from "./Hero";
import HelpSection from "./HelpSection";

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <GettingToKnowUs />
      <HelpSection />
      <Communities />
      <QuoteSection />
      {/* <RealEstatePost /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
