import React from "react";
import { FaPhoneSquare, FaWhatsapp } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { MdAttachEmail } from "react-icons/md";

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="footer__logo">
          <img
            src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1712583655/RealEstate/RS_LOGO_rlcts1.png"
            className="footer__logo--img"
            alt=""
          />
          <p className="footer__logo--text">56 G/F -402, Ojha Complex, </p>
          <p className="footer__logo--text">Sardar Patel Marg, Civil Lines,</p>
          <p className="footer__logo--text">Prayagraj -211001</p>
        </div>

        <div className="footer__contact">
          <div className="contact__number contact">
            <div className="footer-icons">
              <FaPhoneSquare className="footer__contact--icon" />
              <FaWhatsapp className="footer__contact--icon" />
            </div>
            <p className="footer__contact--text">
              <a
                className="footer__contact--text"
                href="https://wa.me/919235452154"
                target="_blank"
                rel="noopener noreferrer"
              >
                +91 9235452154
              </a>
            </p>
          </div>

          <div className="contact__mail contact">
            <div className="footer-icons">
              <MdAttachEmail className="footer__contact--icon" />
            </div>
            <p className="footer__contact--text">
              <a
                className="footer__contact--text"
                href="mailto:admiredevelopers@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                admiredevelopers@gmail.com
              </a>
            </p>
          </div>

          <div className="contact__social contact">
            <div className="footer-icons">
              <GrMail className="footer__contact--icon" />
            </div>
            <p className="footer__contact--text">
              <a
                className="footer__contact--text"
                href="http://www.admiredevelopers.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.admiredevelopers.com
              </a>
            </p>
          </div>
        </div>

        <div className="footer__gallery">
          <img
            src="https://static.wixstatic.com/media/b21e7f_34c8b9d95dec405286a2f4be571c4a0d~mv2.jpg/v1/fill/w_760,h_394,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/b21e7f_34c8b9d95dec405286a2f4be571c4a0d~mv2.jpg"
            className="footer__gallery--img"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
