import React from "react";

const Services = () => {
  const services = [
    {
      title: "1.  Triveni Vedic City...",
      image:
        "https://image.hurimg.com/i/hurriyet/75/0x0/59c8a84245d2a027e83c89f8.jpg",
      description:
        "Real Estate is property of land and the buildings with its natural resources. It is one of the emerging sector in India which has got its potential for everyone need.RS Group is a Prayagraj based Real Estate company which has 11 years of excellence to fulfill yours dream. It has completed many projects successfully.We have started a new project in the name of 'Triveni Vedic City' situated at Sonauti Jhunsi, Prayagraj. It is 12 km away from the Civil Lines. And also The company has launched various residential/commercial plots and houses on very reasonable prices. This is well planed township with all basic amenities required in the present scenario.",
    },
    {
      title: "2.  NATURE VALLEY",
      image: "https://images.mansionglobal.com/im-68486500",
      description:
        "Nature Valley is a prestigious project by R.S Group. It is located near a renowned water park, Fungaon.  Nature Valley is at a distance of 15 Kilometers from the Prayagraj railway station junction. Many educational institutions are located near this project.  Many other completed projects, such as IIIT, National Law University, ITBP headquarters, and BSF surround it. Moreover, many government projects have also been approved nearby. Thanks to all these benefits, investing in a hub-spot is similar to investing money for a secure and bright future!",
    },
    {
      title: "3.  HERITAGE CITY",
      image:
        "https://www.arcillasdecolombia.com/wp-content/uploads/2021/11/PORTOAMERICAS-2.jpg",
      description:
        "Home is more than just a place to spend your days; it can potentially change the quality of a human being's lifestyle for the better. At R.S. Group, we aspire to honestly work to fulfil the requirements of a home or plot at a reasonable price. We are running several projects, and Heritage City is one of them. The well-planned project, located at Puramufti, Prayagraj, Heritage City, is 20 Kilometers from Prayagraj Railway Junction. This township comprises multiple amenities under one umbrella, including but not limited to a shopping mall, playground, full security, water and electricity supply, and school.",
    },
    {
      title: "4.  Admire City",
      image:
        "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715522710/RealEstate/sydney-pic_rie5rj.jpg",
      description:
        "Our Admire City project is located along Reva Road. It is located 30 Kilometers from the City of Prayagraj, near Srijan Hospital, adjacent to Damia Cement Factory. This location provides comfort and accessibility for residents willing to invest in a brighter future. At R.S. Group, our team consists of skilled professionals with 11 years of experience in the real estate field. Thanks to this profound skill and insight in the field, we ensure our projects are equipped with the necessary amenities, regardless of the area.",
    },
    {
      title: "5.  Green Court",
      image:
        "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715522834/RealEstate/5acb8b12-e33a-46e9-ae64-da32847f8f2a.1_vg2v4k.jpg",
      description:
        "Our plotting site, Green Court is located near airport. It is near the Sports Stadium. B P Singh Law College and BSF camp are also nearby. The renowned water park, Fun Gaon Water Park is located near our Green Court plotting site. If you want to invest in a plot that will bring you a fortune in the future, this may be the one for you!",
    },
    // {
    //   title: "4.  LISTING DEVELOPMENT",
    //   image:
    //     "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712509157/RealEstate/IMG_2001_edited_jvzaei.webp",
    //   description:
    //     "When it comes to listing development, RS Group knows how to make your property stand out from the rest. We create an appealing listing that provides a detailed description of your property, complete with high-quality photos that accurately showcase its features. Our team makes sure that your pricing information is accurate and competitive, boosting your chances of attracting the right guests.",
    // },
    // {
    //   title: "5.  GUEST SCREENING AND BOOKING",
    //   image:
    //     "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712509203/RealEstate/Packing_Luggage_gkcjl7.webp",
    //   description:
    //     "Effectively screening every person who request a booking for your property will result in higher quality guests. In the on-boarding meeting we’ll learn more about your preferences for guest screening and our Listing Managers will use the settings on each platform to increase the likelihood of getting bookings from your ideal guest. The number one priority is ensuring everyone who stays takes great care of your place and respects the neighborhood as if it were their own.",
    // },
    // {
    //   title: "6.  GUEST COMMUNICATION",
    //   image:
    //     "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712509287/RealEstate/GUEST_COMMUNICATION_3_kf5nxs.webp",
    //   description:
    //     "We understand the importance of open and prompt communication with our guests. This includes addressing all inquiries, providing check-in instructions, and offering assistance throughout their stay. We strive to make sure your guests feel welcomed and well-informed, so they can have a smooth and enjoyable experience with you.",
    // },
    // {
    //   title: "7.  CHECK-IN AND CHECK-OUT",
    //   image:
    //     "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712509331/RealEstate/Hotel_Bedroom_Entrance_jfktrg.webp",
    //   description:
    //     "We will be responsible to create a comfortable and welcoming environment for your guests. Our check-in and check-out procedures, including key exchange or keyless entry systems, are designed to be efficient and easy-to-use. We also provide guests with all the necessary information about the property and local area, so they can make the most of their stay.",
    // },
    // {
    //   title: "8.  CLEANING AND MAINTENANCE",
    //   image:
    //     "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712509388/RealEstate/Laundry_Room_zzxj2h.webp",
    //   description:
    //     "We go above and beyond to provide exceptional cleaning and maintenance services for your guests. Our team is dedicated to establishing smooth check-in and check-out procedures, and providing guests with all the necessary information they need. ",
    // },
    // {
    //   title: "9.  PRICING AND REVENUE MANAGEMENT",
    //   image:
    //     "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712509464/RealEstate/Financial_Report_mxyqdv.webp",
    //   description:
    //     "This step is designed to help ensure that your property is always at maximum occupancy and revenue. We continuously monitor market trends and adjust pricing strategies accordingly. We also implement dynamic pricing techniques to capitalize on high-demand periods. Our goal is to maximize your revenue potential and keep your property in high demand.",
    // },
    // {
    //   title: "10.  GUEST SATISFACTION AND REVIEWS",
    //   image:
    //     "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712509509/RealEstate/GUEST_COMMUNICATION_edited_hjbzs0.webp",
    //   description:
    //     "We take guest satisfaction and reviews seriously. Our team is committed to providing a seamless and enjoyable experience for our guests, from the moment they book until their departure. We strive to proactively address any concerns or issues during their stay to ensure complete satisfaction and encourage them to leave positive reviews that enhance our property's reputation.",
    // },
    // {
    //   title: "11.  HOME RESTOCK",
    //   image:
    //     "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712509557/RealEstate/SHOPPING_CART_edited_tu38hh.webp",
    //   description:
    //     "RS Group is dedicated to making your Airbnb experience stress-free and profitable for you. Our team monitors all the necessary items like soap, shampoo, tissue paper and household items such as light bulbs, towels, and linens. Our team ensures that your guests have everything they need during their stay. Performing inventory checks after each guest stay and updating our system ensures we can consistently provide hotel level service for your guests.",
    // },
    // {
    //   title: "12.  FINANCIAL REPORTS",
    //   image:
    //     "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712509596/RealEstate/Financial_Report_ddzagi.webp",
    //   description:
    //     "At the end of every month you will receive the reports with a comprehensive overview of the financial performance of your property. These reports are specifically tailored to meet the needs of our clients and offer valuable insights into the revenue, expenses, and profitability of your rental business. With detailed breakdowns and analysis, our financial reports help you track the success of your property, make informed decisions, and optimize your rental strategy. Whether you are a property owner or investor, our reports provide transparency and clarity, ensuring that you have a clear understanding of your property's financial health.",
    // },
  ];
  return (
    <div className="services-section">
      <div className="common-container">
        <div className="services-container">
          {services?.map((service, index) => (
            <div key={index} className="service">
              <div className="service__header">
                <h4 className="service__header--title">{service?.title}</h4>
              </div>

              <div className="service__image">
                <img
                  src={service?.image}
                  className="service__image--img"
                  alt=""
                />
              </div>

              <div className="service__description">
                <p className="service__description--text">
                  {service?.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
