import React from "react";
import { GiFallingLeaf } from "react-icons/gi";
import { Link } from "react-router-dom";

const SingleBlog = ({ blog }) => {
  return (
    <Link
      to={`/blog/${blog?.id}`}
      className="blogs-container"
      style={{ color: "#5d5a61" }}
    >
      <div className="blogs__gallery">
        <img
          src={blog?.data[3]?.banner}
          className="blogs__gallery--img"
          alt=""
        />
      </div>
      <div className="blogs__content">
        <div className="blogs__profile">
          <img
            src={blog?.data[2]?.profileImage}
            className="blogs__profile--img"
            alt=""
          />
          <div className="name-and-time">
            <div className="name">
              <p className="blogs__profile--name">
                {blog?.data[2]?.blogWriter}
              </p>
              <GiFallingLeaf />
            </div>
            <div className="time">
              <p>{blog?.data[0]?.date}</p>
            </div>
          </div>
        </div>
        <div className="content">
          <h3 className="blogs__title">{blog?.data[4]?.heading}</h3>
          <p className="blogs__paragraph">
            {blog?.data[5]?.description?.slice(0, 160)} ...
          </p>
        </div>

        <div className="social">
          {/* <p className="social__view">{blog?.data[1]?.views} views</p>
          <p className="social__react">{blog?.data[1]?.react} ❤️</p> */}
        </div>
      </div>
    </Link>
  );
};

export default SingleBlog;
