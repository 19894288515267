import React from "react";
import Navbar from "../../containers/Shared/Navbar";
import Footer from "../../containers/Shared/Footer";

const OurMission = () => {
  return (
    <div>
      <Navbar />
      <div
        className="whatIsIncluded-section"
        style={{ backgroundColor: "#fff", margin: "10vh 0" }}
      >
        <div className="whatIsIncluded-container">
          <h1 className="whatIsIncluded__header">OUR MISSION</h1>
          <p className="whatIsIncluded__paragraph">
            Our main mission at R.S. Group is to help every person find a home
            they love and create communities where they thrive. We offer
            services that are bound to meet your expectations. Apart from that,
            we aim to create a positive impact on the lives of our clients and
            communities.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurMission;
