import React from "react";
import Navbar from "../../containers/Shared/Navbar";
import Footer from "../../containers/Shared/Footer";

const Gallery = () => {
  const galleryImages = [
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715570301/RealEstate/pexels-photo-8373204_dcctdx.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715570396/RealEstate/pexels-photo-9051772_jvki0h.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715570423/RealEstate/pexels-photo-11382336_e6w52d.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712476338/RealEstate/b21e7f_1bd00026fcce417c83190fbf9551c285_mv2_sztmyv.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712476386/RealEstate/b21e7f_0ad0cedc0ec844d29a819680aad25ef4_mv2_xkrrin.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712476429/RealEstate/b21e7f_31e07e8ff87548c69d7948bca6d64284_mv2_wjdqbp.webp",
    "https://static.wixstatic.com/media/b21e7f_34c8b9d95dec405286a2f4be571c4a0d~mv2.jpg/v1/fill/w_760,h_394,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/b21e7f_34c8b9d95dec405286a2f4be571c4a0d~mv2.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712470412/RealEstate/b21e7f_b7797f4dc82140c0a93de2906ed99773_mv2_akbaem.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712475669/RealEstate/b21e7f_58e27763949b48bc9840f7e441849cb6_mv2_qekuuy.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712476458/RealEstate/b21e7f_98dae90f1d9145f4b847ed08a42a7c39_mv2_onrhu5.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712476500/RealEstate/b21e7f_ca4615b5a78d46e4843240f74b4a1339_mv2_pq6b6y.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712476527/RealEstate/b21e7f_6c6ac113a22342b3870765b2799d3471_mv2_emksge.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712476566/RealEstate/b21e7f_47e7037e2b7a439cb7509118dc3db9eb_mv2_jpxb2m.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712475669/RealEstate/b21e7f_58e27763949b48bc9840f7e441849cb6_mv2_qekuuy.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712476682/RealEstate/b21e7f_8f752437e2fe4f59a0d7a29b9c06e0f9_mv2_srjfzo.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1712475669/RealEstate/b21e7f_58e27763949b48bc9840f7e441849cb6_mv2_qekuuy.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715522834/RealEstate/5acb8b12-e33a-46e9-ae64-da32847f8f2a.1_vg2v4k.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715522710/RealEstate/sydney-pic_rie5rj.jpg",
  ];

  return (
    <div>
      <Navbar />
      <div className="gallery">
        <div className="common-container">
          <h1 className="gallery__heading">Gallery</h1>
          <div className="gallery__container">
            {galleryImages?.map((image, i) => (
              <div key={i} className="image-wrapper">
                <img src={image} className="gallery__container--image" alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
