import React from "react";
// import videoFile from "../../assets/videos/file.mp4";

const Hero = () => {
  return (
    <div className="hero-section">
      {/* <div className="hero-container"></div> */}
      <video
        id="comp-lqdsw85l_video"
        crossOrigin="anonymous"
        playsInline
        preload="auto"
        loop
        tabIndex="-1"
        autoPlay
        muted
        src="https://video.wixstatic.com/video/b21e7f_5a6376078b2d43e08d2a8c41223936c3/1080p/mp4/file.mp4"
        // src={videoFile}
        __idm_id__="286722"
      ></video>
    </div>
  );
};

export default Hero;
