import React from "react";

const About = () => {
  return (
    <div>
      <div
        className="whatIsIncluded-section"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="whatIsIncluded-container">
          <h1 className="whatIsIncluded__header" style={{ color: "#beae86" }}>
            The Best Real Estate Agency in Prayagraj
          </h1>
          <div style={{ textAlign: "left" }}>
            <p className="whatIsIncluded__paragraph">
              <span style={{ color: "#beae86" }}>R.S. Group</span>, established
              by R.K. Sahu, located in the Holy City, Prayagraj, specializes in
              assisting with buying and selling property. With 11 years of
              expertise in the field, our skilled professionals have great
              insight into the ins and outs of real estate in Prayagraj.
            </p>
            <br />
            <p className="whatIsIncluded__paragraph">
              We have customers based in over 30 districts in Uttar Pradesh,
              with a net asset value of above 30 crores.
            </p>
            <br />
            <p className="whatIsIncluded__paragraph">
              We have worked on several projects located near Prayagraj. Some of
              our most renowned projects include:
            </p>

            <div style={{ textAlign: "left", marginLeft: "10px" }}>
              <li className="whatIsIncluded__paragraph">Green Court</li>
              <li className="whatIsIncluded__paragraph">Admire City</li>
              <li className="whatIsIncluded__paragraph">Nature Valley</li>
              <li className="whatIsIncluded__paragraph">Heritage City</li>
            </div>
            <br />
            <p className="whatIsIncluded__paragraph">
              We aspire to be honest and transparent with our clients, giving
              them what they deserve. We believe owning a house is a longing
              dream of society, and our mission is to fulfil this dream.
            </p>
            <br />
            <p className="whatIsIncluded__paragraph">
              To book a free consultation, contact us at +91 9235452154!
            </p>

            <br />

            <p className="whatIsIncluded__paragraph">
              <strong>How Can We Help You?</strong>
            </p>
            <div style={{ textAlign: "left", marginLeft: "10px" }}>
              <li className="whatIsIncluded__paragraph">
                <strong>Constructed Property</strong>
              </li>
              <li className="whatIsIncluded__paragraph">
                <strong>Developed Plots</strong>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
