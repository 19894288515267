import React from "react";
import Navbar from "../../containers/Shared/Navbar";
import Footer from "../../containers/Shared/Footer";
import Blogs from "./Blogs";

const BlogPage = () => {
  return (
    <div>
      <Navbar />
      <Blogs />
      <Footer />
    </div>
  );
};

export default BlogPage;
