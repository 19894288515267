import React from "react";

const WhoWeAre = () => {
  return (
    <div className="whatIsIncluded-section">
      <div className="whatIsIncluded-container">
        <h1 className="whatIsIncluded__header">Who We Are?</h1>
        <p className="whatIsIncluded__paragraph">
          We are a real estate agency based in Prayagraj, formerly known as
          Allahabad. Our expertise and knowledge in the real estate field
          extends back to 11 years. We have established ourselves as a respected
          and trusted company while serving clients in over 30 districts in
          Uttar Pradesh.
        </p>
        <p className="whatIsIncluded__paragraph">
          We live by the slogan, “Burning Desire to Grow.”
        </p>
      </div>
    </div>
  );
};

export default WhoWeAre;
