import React from "react";

const PropertyManagement = () => {
  return (
    <div className="propertyManagement-section">
      <div className="common-container">
        <div className="propertyManagement-container">
          <div className="propertyManagement__gallery">
            <img
              src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1712485298/RealEstate/b21e7f_f42c514b5c234a688cb60e03fb46d143_mv2_xx8xiw.webp"
              className="propertyManagement__gallery--img"
              alt=""
            />
          </div>

          <div className="propertyManagement__content">
            <h1 className="propertyManagement__content--heading">
              PROPERTY MANAGEMENT
            </h1>
            <p className="propertyManagement__content--paragraph">
              Welcome to RS Group Real Estate's short term property management
              service! Our experienced team is here to help you maximize the
              potential of your rental property, providing top-notch management
              services that will ensure a stress-free experience for both you
              and your guests.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyManagement;
