import React from "react";

const WhyChooseUs = () => {
  return (
    <div className="whatIsIncluded-section" style={{ backgroundColor: "#fff" }}>
      <div className="whatIsIncluded-container">
        <h1 className="whatIsIncluded__header">Why Should You Choose Us?</h1>
        <p className="whatIsIncluded__paragraph">
          Why do we stand out with many rising real estate agencies in the
          property market?
        </p>

        <div style={{ textAlign: "left", marginTop: "20px" }}>
          <li className="whatIsIncluded__paragraph">
            We put our client’s needs first. To us, customers are our King.{" "}
          </li>
          <li className="whatIsIncluded__paragraph">
            We work closely with our clients to understand their goals and
            preferences.
          </li>
          <li className="whatIsIncluded__paragraph">
            We maintain transparency and honesty. Our honest communication is a
            symbol that defines us.{" "}
          </li>
          <li className="whatIsIncluded__paragraph">
            We address our customer’s inquiries with the help of our in-depth
            knowledge in the field.{" "}
          </li>
          <li className="whatIsIncluded__paragraph">
            We walk our clients through the process to help them make the right
            decision.
          </li>
          <li className="whatIsIncluded__paragraph">
            We provide excellent delivery with a great understanding of what our
            customers want.{" "}
          </li>
          <li className="whatIsIncluded__paragraph">
            We build relationships followed by trust, reliability, and mutual
            respect.
          </li>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
