import React from "react";
import Navbar from "../../containers/Shared/Navbar";
import Footer from "../../containers/Shared/Footer";
import { blogData } from "../../utils/blogData";
import { useParams } from "react-router";

const BlogDetails = () => {
  const { id } = useParams();

  const blog = blogData.find((blog) => blog?.id === id);

  return (
    <div className="blogDetails">
      <Navbar />
      <div className="common-container" style={{ marginBottom: "40px" }}>
        {blog?.data?.map((b, i) => (
          <div key={i} className="blogDetails-wrapper">
            {b?.banner && (
              <div style={{ width: "100%" }}>
                <img src={b?.banner} alt="" className="blogDetails-banner" />
                <br />
                <br />
                <br />
              </div>
            )}
            {b?.heading && (
              <h1 className="blogDetails-heading">{b?.heading}</h1>
            )}
            {b?.image && (
              <div className="blogDetails-image-wrapper">
                <img src={b?.image} alt="" className="blogDetails-image" />
                <br />
                <br />
                <br />
              </div>
            )}
            {b?.title && (
              <h2 style={{ fontWeight: "700", margin: "20px 0 10px" }}>
                {b?.title}
              </h2>
            )}
            {b?.description && (
              <p style={{ fontSize: "17px", lineHeight: "1.4" }}>
                {b.description}
              </p>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetails;
