import React from "react";
import Navbar from "../../containers/Shared/Navbar";
import Footer from "../../containers/Shared/Footer";

const OurVision = () => {
  return (
    <div>
      <Navbar />
      <div
        className="whatIsIncluded-section"
        style={{ backgroundColor: "#fff", margin: "10vh 0" }}
      >
        <div className="whatIsIncluded-container">
          <h1 className="whatIsIncluded__header">OUR VISION</h1>
          <p className="whatIsIncluded__paragraph">
            Our vision is to redefine the real estate experience. We create
            spaces that inspire and enrich lives by providing comfort. We aspire
            to be your trusted partner in building communities, where every
            property is a mirror reflection of our dedication, innovation and
            exceptional quality. With the support of our customers, our sole
            missions is to shape the future of real estate by providing
            exceptional comfort in the lives of an ordinary human being.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurVision;
