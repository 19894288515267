import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const QuoteSection = () => {
  const items = [
    {
      quote:
        "We really enjoy working with Doug and Bianca. They have a strong understanding of the area, are fluent in English and Portuguese, provide valuable market insights, and guide you through the best available options. The entire process was seamless and completed faster than our expectations.",
    },
    {
      quote:
        "Doug listed our home in Jupiter and did an excellent job helping us evaluate the appropriate listing price. He created a comprehensive home listing with detailed property information and professional pictures, and guided us through the entire process seamlessly. We highly recommend RS Group.",
    },
    {
      quote:
        "RS Group has been managing our Vacation Rental Property in Fort Lauderdale for the past 6 months, and we are extremely satisfied with the results. It has exceeded our expectations by 100%, and the property is fully booked during the high season.",
    },
  ];

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 2000,
    cssEase: "ease",
    dots: true,
  };

  return (
    <div className="quote-section">
      <div className="common-container">
        <div className="quote-container">
          <Slider {...settings} className="quote__body">
            {items.map((item, index) => (
              <div key={index} className="quote__item">
                <p className="quote__item--text">{item?.quote}</p>
                <div className="quote__item--overlay"></div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default QuoteSection;
