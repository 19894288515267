import React from "react";

const WhatIsIncluded = () => {
  return (
    <div className="whatIsIncluded-section">
      <div className="whatIsIncluded-container">
        <h1 className="whatIsIncluded__header">WHAT IS INCLUDED</h1>
        <p className="whatIsIncluded__paragraph">
          Our Full Service Manager offering is specifically designed to give you
          the ultimate convenience and benefits of short-term rentals, while
          eliminating the need for you to deal with the day-to-day operational
          hassles or invest time in learning how to maximize your earnings. With
          our comprehensive services, we take care of every aspect of the
          short-term rental process, allowing you to sit back, relax, and enjoy
          the rewards of your property investment.
        </p>
      </div>
    </div>
  );
};

export default WhatIsIncluded;
