import { Toaster } from "react-hot-toast";
import "./App.css";
import Routers from "./routes";
import "./styles/_global.scss";
import { FaWhatsapp } from "react-icons/fa";

function App() {
  const shareOnWhatsApp = () => {
    const message = encodeURIComponent("Hi RS Group");

    const whatsappUrl = `https://wa.me/+919235452154?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div className="body-full-section-layer">
      <Toaster />
      <Routers />
      <div
        style={{
          borderRadius: "100%",
          background: "#34a853",
          position: "fixed",
          bottom: "16px",
          right: "16px",
          padding: "8px 10px",
          cursor: "pointer",
        }}
        onClick={() => shareOnWhatsApp()}
      >
        <FaWhatsapp style={{ width: "32px", height: "32px", color: "#fff" }} />
      </div>
    </div>
  );
}

export default App;
