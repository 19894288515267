import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
import PropertyManagementPage from "../pages/PropertyManagement";
import BlogPage from "../pages/BlogPage/BlogPage";
import AboutUs from "../pages/AboutUsPage/AboutUs";
import OurMission from "../pages/OurMission/OurMission";
import OurVision from "../pages/OurVision/OurVision";
import PlotImages from "../pages/PlotImages/PlotImages";
import Gallery from "../pages/Gallery/Gallery";
import BlogDetails from "../pages/BlogPage/BlogDetails";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route
          exact
          path="/property-management"
          element={<PropertyManagementPage />}
        />
        <Route exact path="/plot-images" element={<PlotImages />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/our-mission" element={<OurMission />} />
        <Route exact path="/our-vision" element={<OurVision />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/blog" element={<BlogPage />} />
        <Route exact path="/blog/:id" element={<BlogDetails />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
