import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
// import { IoIosArrowDown } from "react-icons/io";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  return (
    <div className={`navbar-section`}>
      <div className="navbar-container common-container">
        <div className="navbar__logo">
          <Link to="/">
            <img
              src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1712583655/RealEstate/RS_LOGO_rlcts1.png"
              className="navbar__logo--img"
              alt=""
            />
          </Link>
        </div>

        <div className="navbar-right">
          <div className="large-device-menu" style={{ color: "#fff" }}>
            <Link to="/" style={pathname === "/" ? { color: "#dbb900" } : {}}>
              HOME
            </Link>
            <Link
              to="/property-management"
              style={
                pathname === "/property-management" ? { color: "#dbb900" } : {}
              }
            >
              PROPERTY MANAGEMENT
            </Link>

            {/* <div className="dropdown" style={{ float: "right" }}>
              <span className="dropdown__btn">
                INVESTORS <IoIosArrowDown className="dropdown__btn--icon" />
              </span>
              <div className="dropdown-content">
                <Link
                  to="/investors/first-time-investors"
                  style={
                    pathname === "/investors/first-time-investors"
                      ? { color: "#dbb900" }
                      : {}
                  }
                >
                  FIRST TIME INVESTORS
                </Link>
                <Link
                  to="/investors/rental-property-calculator"
                  style={
                    pathname === "/investors/rental-property-calculator"
                      ? { color: "#dbb900" }
                      : {}
                  }
                >
                  RENTAL PROPERTY CALCULATOR
                </Link>
              </div>
            </div> */}
            <Link
              to="/about-us"
              style={pathname === "/about-us" ? { color: "#dbb900" } : {}}
            >
              ABOUT US
            </Link>
            <Link
              to="/blog"
              style={pathname === "/blog" ? { color: "#dbb900" } : {}}
            >
              BLOG
            </Link>

            <div className="dropdown" style={{ float: "right" }}>
              <span className="dropdown__btn">
                MORE <IoIosArrowDown className="dropdown__btn--icon" />
              </span>
              <div className="dropdown-content">
                <a
                  href="https://admiredevelopers.com/about-us.php"
                  target="_blank"
                >
                  BUSINESS WEBSITE
                </a>
                <Link
                  to="/our-mission"
                  style={
                    pathname === "/our-mission" ? { color: "#dbb900" } : {}
                  }
                >
                  OUR MISSION
                </Link>
                <Link
                  to="/our-vision"
                  style={pathname === "/our-vision" ? { color: "#dbb900" } : {}}
                >
                  OUR VISION
                </Link>
                <Link
                  to="/plot-images"
                  style={
                    pathname === "/plot-images" ? { color: "#dbb900" } : {}
                  }
                >
                  PLOT IMAGES
                </Link>
                <Link
                  to="/gallery"
                  style={pathname === "/gallery" ? { color: "#dbb900" } : {}}
                >
                  GALLERY
                </Link>
              </div>
            </div>
          </div>

          <div className="small-device-menu">
            <div className="menu-bar">
              <svg
                onClick={() => setIsOpen(true)}
                className="small-device__sidebar-toggle"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="MenuIcon"
                style={{ color: "#000" }}
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </svg>
            </div>
            <div className={`sidebar-wrapper ${isOpen ? "open" : ""}`}>
              <div className={`sidebar ${isOpen ? "" : "closeAnimation"} `}>
                <div className="sidebar__header">
                  <div></div>
                  <div className="header__closeButton">
                    <button onClick={() => setIsOpen(false)} className="button">
                      <AiOutlineClose className="button-icon" />
                    </button>
                  </div>
                </div>
                <div className="sidebar__body">
                  <li className="body__menu">
                    <Link
                      to="/"
                      style={pathname === "/" ? { color: "#dbb900" } : {}}
                    >
                      HOME
                    </Link>
                  </li>
                  <li className="body__menu">
                    <Link
                      to="/property-management"
                      style={
                        pathname === "/services" ? { color: "#dbb900" } : {}
                      }
                    >
                      PROPERTY MANAGEMENT
                    </Link>
                  </li>
                  <li className="body__menu">
                    <a
                      href="https://admiredevelopers.com/about-us.php"
                      target="_blank"
                    >
                      BUSINESS WEBSITE
                    </a>
                  </li>
                  <li className="body__menu">
                    <Link
                      to="/our-mission"
                      style={
                        pathname === "/our-mission" ? { color: "#dbb900" } : {}
                      }
                    >
                      OUR MISSION
                    </Link>
                  </li>
                  <li className="body__menu">
                    <Link
                      to="/our-vision"
                      style={
                        pathname === "/our-vision" ? { color: "#dbb900" } : {}
                      }
                    >
                      OUR VISION
                    </Link>
                  </li>
                  <li className="body__menu">
                    <Link
                      to="/plot-images"
                      style={
                        pathname === "/plot-images" ? { color: "#dbb900" } : {}
                      }
                    >
                      PLOT IMAGES
                    </Link>
                  </li>
                  <li className="body__menu">
                    <Link
                      to="/gallery"
                      style={
                        pathname === "/gallery" ? { color: "#dbb900" } : {}
                      }
                    >
                      GALLERY
                    </Link>
                  </li>
                  {/* <li className="body__menu">
                    <Link
                      to="/investors/first-time-investors"
                      style={
                        pathname === "/investors/first-time-investors"
                          ? { color: "#dbb900" }
                          : {}
                      }
                    >
                      FIRST TIME INVESTORS
                    </Link>
                  </li> */}
                  {/* <li className="body__menu">
                    <Link
                      to="/investors/rental-property-calculator"
                      style={
                        pathname === "/investors/rental-property-calculator"
                          ? { color: "#dbb900" }
                          : {}
                      }
                    >
                      RENTAL PROPERTY CALCULATOR
                    </Link>
                  </li> */}
                  <li className="body__menu">
                    <Link
                      to="/about-us"
                      style={
                        pathname === "/about-us" ? { color: "#dbb900" } : {}
                      }
                    >
                      ABOUT US
                    </Link>
                  </li>
                  <li className="body__menu">
                    <Link
                      to="/blog"
                      style={pathname === "/blog" ? { color: "#dbb900" } : {}}
                    >
                      BLOG
                    </Link>
                  </li>
                </div>
              </div>
              <div
                className="sidebar__backdrop"
                onClick={() => setIsOpen(false)}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
