import React from "react";
import About from "./About";

const GettingToKnowUs = () => {
  return (
    <div className="gettingToKnowUs-section">
      <div className="gettingToKnowUs-wrapper">
        <div className="gettingToKnowUs__content">
          {/* <h1 className="gettingToKnowUs__content--heading">
            GETTING TO KNOW US
          </h1>
          <p className="paragraph">
            <span className="primary-color">RS Group Real Estate</span>, located
            in the Fort Lauderdale area, specializes in assisting you with
            <span className="primary-color">BUYING</span> and{" "}
            <span className="primary-color">SELLING HOMES</span>, as well as{" "}
            <span className="primary-color">MANAGING VACATION RENTALS</span> in
            South Florida. We are here to help you navigate the process
            seamlessly.
          </p>
          <p className="paragraph">
            Our team of experienced Brazilian and American real estate agents is
            dedicated to providing exceptional service and guiding you every
            step of the way.
          </p>
          <p className="paragraph">
            Whether you're looking to{" "}
            <span className="primary-color">MOVE</span> or{" "}
            <span className="primary-color">INVEST</span> in the area, we have
            the expertise and local knowledge to assist you in finding the{" "}
            <span className="primary-color">PERFECT PROPERTY</span>.
          </p>
          <p className="paragraph">
            From connecting you to a reputable agent to securing financing and
            completing the closing process, we'll ensure a{" "}
            <span className="primary-color">SIMPLE, SMOOTH</span> and
            <span className="primary-color">SUCCESSFUL TRANSACTION</span>.
          </p>
          <p className="paragraph">
            Trust RS Group to make your real estate dreams in South Florida a
            reality.
          </p>
          <button className="contact__btn">CONTACT US</button> */}
          <About />
        </div>
        <div className="gettingToKnowUs__banner">
          <img
            src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1715582515/RealEstate/pexels-photo-93400_odbo5t.webp"
            className="gettingToKnowUs__banner--img"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default GettingToKnowUs;
