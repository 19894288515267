import React from "react";

const HelpSection = () => {
  return (
    <div className="helpSection">
      <div className="helpSection-container">
        <div className="helpSection__header">
          <h1 className="helpSection__header--text">HOW CAN WE HELP YOU?</h1>
        </div>

        <div className="helpSection-itemContainer">
          <div
            className="helpSection__item"
            style={{
              backgroundImage:
                "url(https://res.cloudinary.com/djfzcmvmr/image/upload/v1712481914/RealEstate/House_with_Pool_bcjdlr.webp)",
            }}
          >
            <div className="item-text-wrapper">
              <h4 className="item--text">WHAT IS MY HOME WORTH?</h4>
            </div>
          </div>
          <div
            className="helpSection__item"
            style={{
              backgroundImage:
                "url(https://res.cloudinary.com/djfzcmvmr/image/upload/v1712559319/RealEstate/Modern_Living_Room_jbpjmg.webp)",
            }}
          >
            <div className="item-text-wrapper">
              <h4 className="item--text">DREAM HOME FINDER</h4>
            </div>
          </div>
          <div
            className="helpSection__item"
            style={{
              backgroundImage:
                "url(https://res.cloudinary.com/djfzcmvmr/image/upload/v1712559416/RealEstate/Speed_Boats_u30zry.webp)",
            }}
          >
            <div className="item-text-wrapper">
              <h4 className="item--text">PROPERTY MANAGEMENT</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpSection;
