import React from "react";
import Navbar from "../../containers/Shared/Navbar";
import Footer from "../../containers/Shared/Footer";

const PlotImages = () => {
  const plotImages = [
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715570301/RealEstate/pexels-photo-8373204_dcctdx.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715570396/RealEstate/pexels-photo-9051772_jvki0h.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715570423/RealEstate/pexels-photo-11382336_e6w52d.webp",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715575554/RealEstate/pexels-photo-4982914_cvz3rc.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715575507/RealEstate/pexels-photo-13599821_jamsw5.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715575305/RealEstate/pexels-photo-3814205_c4g6ov.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715575685/RealEstate/pexels-photo-4034593_eixplo.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715575772/RealEstate/pexels-photo-11333726_j3ie4h.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715575823/RealEstate/pexels-photo-10846260_ktmsb2.jpg",
  ];

  return (
    <div>
      <Navbar />
      <div className="gallery">
        <div className="common-container">
          <h1 className="gallery__heading">Plot Images</h1>
          <div className="gallery__container">
            {plotImages?.map((image, i) => (
              <div key={i} className="image-wrapper">
                <img src={image} className="gallery__container--image" alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PlotImages;
